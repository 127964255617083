import React from 'react'
import '../styles/singleartworkitem.css'
import {IoIosArrowBack} from 'react-icons/io'
import {IoIosArrowForward} from 'react-icons/io'

const ArtworkModal = ({setModal, increment, decrement, artworkRender, handleModal}) => {



  return (
    <section className="artwork-modal-background">

      <div className="artwork-modal-container">
      <IoIosArrowBack id="" label="Back" 
                        style={{height: '4em', width: '4em', color: "white"}}
                        onClick={decrement} className="arrow"/>
                        
                
      {artworkRender()}
      <IoIosArrowForward id="" label="Forward" 
                        style={{height: '4em', width: '4em', color: "white"}}
                        onClick={increment} className="arrow"/>
      
      <button className="close-btn" onClick={() => handleModal()}>X</button>
      </div>
      
    </section>
  )
}

export default ArtworkModal