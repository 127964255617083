import React, { useEffect, useState } from 'react'
import '../styles/singleartworkitem.css'

import sanityClient from '../client.js'
import { useParams } from 'react-router-dom'
import {IoIosArrowBack} from 'react-icons/io'
import {IoIosArrowForward} from 'react-icons/io'
import { RiArrowGoBackLine } from 'react-icons/ri'
import ZoundsBackNav from './ZoundsBackNav.js';
import MenuBackNav from './MenuBackNav.js';
import { NavLink } from 'react-router-dom'
import Spinner from './Spinner'

import SmallMenuBackNav from './SmallMenuBackNav'
import performanceImage from '../assets/performance.gif'

import ArtworkModal from './ArtworkModal'
import { RiZoomInLine } from "react-icons/ri";


const SinglePerformance = () => {

    const [ performance, setPerformance] = useState(null)
    const { slug } = useParams()
    const [counter, setCounter] = useState(0)
    const [artworksLength, setArtworksLength] = useState(0)

    const increment = () => {
        const plusValue = counter + 1
        if(plusValue < artworksLength){
            setCounter(plusValue)   
        }else{
            setCounter(0)
        }   
    }

    const decrement = () => {
        const minusValue = counter -1
        if(minusValue > 0){
        setCounter(minusValue)
        }else{
        setCounter(artworksLength -1)
        }
    }

    const dimensions = (string) => {
        const twoParts = string.split("-")
        const dimensions = twoParts[1].split("x")
        const one = parseFloat(dimensions[0])
        const two = parseFloat(dimensions[1].slice(0,-4))
        console.log(one, two)
        if(one > two){
            return true
        }else{
            return false
        }
    }

    const artworkRender = () => {
        if(artworksLength === 0){
            return null
        }else{
            const imageRatio = dimensions(performance.artworks[counter].mainImage.asset.url)
            return <img src={performance.artworks[counter].mainImage.asset.url} alt={performance.artworks[counter].description ? performance.artworks[counter].description : performance.title} style={{width: `${imageRatio ? "": "auto"}`, height: `${imageRatio ? "auto" : ""}`, maxWidth: `${imageRatio ? "100%" : ""}`, maxHeight: `${imageRatio ? "" : "100%"}`   }}/>

            // return <img src={performance.artworks[counter].mainImage.asset.url} alt={performance.title} className="image-frame"/>
        }
    }

    const videoRender = () => {
        if(!performance.video){
            return null
        }else{
        return <div className="video">
            {/* {performance.video.url} */}
            {/* <iframe src={vimeolink} id="vimeo" title='vimeo' width='640' height='360' frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen></iframe> */}

         <iframe src={performance.video.url} id="vimeo" title='vimeo' width='640' height='360' frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen></iframe>
        {/* <iframe title="vimeo-player" src="https://player.vimeo.com/video/224445685?h=54f44d6bc3" width="640" height="360" frameborder="0" allowfullscreen></iframe> */}
        </div>
        }
    }

    useEffect(()=> {
        sanityClient.fetch(`*[slug.current == "${slug}" && _type == "performance"]{
            title,
            artist,
            slug,
            mainImage,
            body,
            video,
            "artworks": artworks[]->{
                mainImage{
                    asset->{
                        url
                    }
                },
                description,
                imageAlt,
            },
        }`)
        .then((data) => setPerformance(data[0]))
        .catch(console.error);
    }, [slug])


    const [modal, setModal] = useState(false)

    const handleModal = () => {
        setModal(!modal)
    }

    useEffect(()=> {
        if(!performance){
            setArtworksLength(0)
        }else{
            setArtworksLength(performance.artworks ? performance.artworks.length : 0)
        }
    }, [performance])

    if(!performance) return <Spinner/>
  return (
    <main className="single-artwork-super-container">
            {modal && <ArtworkModal handleModal={handleModal} setModal={setModal} increment={increment} decrement={decrement} artworkRender={artworkRender}/>}

{/* Only show on small screens: */}
        <header className="small-conditional-header">
        <RiArrowGoBackLine id="" label="Back" 
                    style={{height: '3em', width: '3em', color: "white"}}/>
        <img src={performanceImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

    <section className="single-artwork-cols">


        {/* back button / spacer */}
    <div className="back-btn-container">
                <NavLink to="/performance">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>
        <div className="artwork-display">
            <div className="display">
            {artworkRender()}
            {videoRender()}
            <h4>{performance.artworks ? performance.artworks[counter].description : performance.title}</h4>
           {performance.video ? null : 
            <button aria-label="open image modal" className="zoom-btn" onClick={handleModal}><RiZoomInLine style={{height: '2em', width: '2em', color: "white"}}/></button>
           }
            
            </div>
            {artworksLength ===0 ? null :  <div className="btn-container">
            <IoIosArrowBack id="" label="Back" 
                style={{height: '4em', width: '4em', color: "white"}}
                onClick={decrement} className="arrow"/>
                        
            <IoIosArrowForward id="" label="Forward" 
                style={{height: '4em', width: '4em', color: "white"}}
                onClick={increment} className="arrow"/>
         </div>}
           
        </div>

     
   
        <div className="other-items">
                    <ZoundsBackNav />
                    <MenuBackNav />
        </div>

</section>
</main>

  );
};

export default SinglePerformance;
