import React from 'react'
import image from '../assets/red-blob.png'
import '../styles/spinner.css'
const Spinner = () => {
  return (
      <section className="spin-container">
    <div className="spinner">
        <img src={image} alt="" className="spin-img"/>
    </div>
    <h2>Loading...</h2>
    </section>
  )
}

export default Spinner