import React from 'react'
import { NavLink } from 'react-router-dom'
import yellow_a from '../assets/yellow-beast-sm.png'
import '../styles/menubacknav.css'

export default function SmallMenuBackNav() {
    return (
        <div className="small-yellow-back">
            <div >
                <NavLink to="/menu">
                <img src={yellow_a} className="menu-back-image" alt="" />
                </NavLink>
            </div>
        </div>
    )
}