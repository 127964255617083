import React from 'react'
import '../styles/nihilitykunstkrudzounds.css'

import { NavLink } from 'react-router-dom'
import MenuBackNav from './MenuBackNav'
import HoverImage from './HoverImage'

import musick from '../assets/musick.gif'
import musick2 from '../assets/musick2.gif'
import performance from '../assets/performance.gif'
import performance2 from '../assets/performance2.gif'

// import menuImage4a from '../assets/linx.gif'
// import menuImage4b from '../assets/linx.2.gif'
// import menuImage5a from '../assets/contact.1.gif'
// import menuImage5b from '../assets/contact.2.gif'
import background from '../assets/replace_zounds.jpg'

export default function Zounds() {
    return (
        <main className="main-container zounds" style={{backgroundImage: `url(${background})`}}>
           <section className="col-display">
            <div className="page-items">

                <div className="page-item" id="nav-hover">
                <NavLink to="/musick">
                <HoverImage image1={musick} image2={musick2}/>
                </NavLink>
                </div>

                <div className="page-item" id="nav-hover">
                <NavLink to="/performance">
                <HoverImage image1={performance} image2={performance2}/>
                </NavLink>
                </div>

            </div>

            <div className="other-items-nihility-kunstkrud-zounds">
            {/* <div className="nav-pair">
                            <div className="linx" id="nav-hover">
                                <NavLink to="/linx">
                                <HoverImage image1={menuImage4a} image2={menuImage4b}/>
                                </NavLink>
                            </div>
                            
                            <div className="contact" id="nav-hover">
                                <NavLink to="/contact">
                                <HoverImage image1={menuImage5a} image2={menuImage5b}/>
                                </NavLink>
                            </div>
                    </div> */}
       

                <div className="">
                    <MenuBackNav />
                </div>
            </div>

            </section>
        </main>
    )
}
