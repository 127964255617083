import React, {useState, useEffect} from 'react'
import '../styles/drawingskulpchurperformance.css'

import sanityClient from '../client.js'
import ZoundsBackNav from './ZoundsBackNav'
import MenuBackNav from './MenuBackNav'
import { NavLink } from 'react-router-dom'
import Spinner from './Spinner'
import { RiArrowGoBackLine } from 'react-icons/ri'
import performance from '../assets/performance.gif'
import SmallMenuBackNav from './SmallMenuBackNav'


export default function Performance() {

    const [performances, setPerformances] = useState(null)
    const [hover, setHover] = useState(false)
    const [hoveredImage, setHoveredImage] = useState(null)

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(()=> {
        sanityClient.fetch(`*[_type == "performance"]{
            title,
            artist,
            slug,
            sequence,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            dateInfo,
            projectInfo,
        }`)
        .then((data) => setPerformances(data.sort(compare)))
        .catch(console.error);
    }, [])

    const mouseOver = (e) => {
        setHover(true)
        setHoveredImage({src: e.target.currentSrc, alt: e.target.alt, title: e.target.title, date: e.target.dateInfo, project: e.target.projectInfo})
    }

    const mouseOut = (e) => {
        setHover(false)
    }

    const imageRender = (performance) => {
        try {
            return <img src={performance.mainImage.asset.url} alt={performance.title} className="grid-image" onMouseOver={mouseOver} onMouseOut={mouseOut}/>
        }catch {
            return <img src={"red-blob.png"} alt={performance.title} className="grid-image" onMouseOver={mouseOver} onMouseOut={mouseOut}/>
        }
    }

    if(!performances) return <Spinner/>
    return (
        <main className="super-container">

               {/* Only show on small screens: */}
        <header className="small-conditional-header">
        <NavLink to="/zounds">
                <RiArrowGoBackLine id="" label="Back" 
                            style={{height: '3em', width: '3em', color: "white"}}/>
                </NavLink>
        <img src={performance} className="" alt="" />
        <SmallMenuBackNav />
        </header>
            
            <section className="col-display">

            <div className="grid">
                {performances && performances.map((performance, index) => (
                    <div key={index}>
                    {
                        performance.slug ? 
                    <NavLink to={"/performance/"+performance.slug.current} key={performance.slug.current}>
                    <div key={index} className="grid-item" >
                        {imageRender(performance)}
                    </div>
                    </NavLink>
                    : null
                    }
                   </div>
                   
                ))}
            </div>

            <div className="display-frame">
                   {hover ? 
                   <div className="display-frame-image">
                   <img src={hoveredImage.src} alt={hoveredImage.alt}/>
                   <h4>{hoveredImage.alt}</h4>
                   <p>{hoveredImage.date ? hoveredImage.date : ""}</p>
                   <p>{hoveredImage.project ? hoveredImage.project : ""}</p>
                   </div>
                   : null} 
            </div>

            <div className="other-items">
                <ZoundsBackNav />
                <MenuBackNav />
            </div>
            </section>
        </main>
    )
}
