import React from 'react'
import { NavLink } from 'react-router-dom'
// import image from '../assets/animation.gif'

export default function Intro() {
    return (
        <NavLink to="/menu">
        <div className="intro-container">
            
            <img src="/animation.gif" alt="gif of some art flashing" />
            
        </div>
        </NavLink>
    )
}
