import React from 'react'
import {NavLink} from 'react-router-dom'
import navImage1a from '../assets/back-nihility.jpg'
import navImage1b from '../assets/back-nihility.jpg'

const NihilityBackNav = () => {
    return (
        <div className="" id="nihility" aria-label='Back'>
        <NavLink to="/nihility">
        <img src={navImage1a} className="side-nav-image" alt="" />
        <img src={navImage1b} className="side-nav-image" alt="" />
        </NavLink>
    </div>
    )
}

export default NihilityBackNav
