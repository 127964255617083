import React, {useEffect, useState} from 'react'
import sanityClient from "../client.js"
import BlockContent from "@sanity/block-content-to-react";
import Spinner from './Spinner'

import { NavLink } from 'react-router-dom'

import { RiArrowGoBackLine } from 'react-icons/ri'
import wordsImage from '../assets/words.gif'
import SmallMenuBackNav from './SmallMenuBackNav'
import NihilityBackNav from './NihilityBackNav.js'
import MenuBackNav from './MenuBackNav.js'
import '../styles/gigsnewswords.css'

export default function Words() {

    const [wordsData, setWordsData] = useState(null)

    // sorts reverse order, largest int at head of array
    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(() => {
        sanityClient.fetch(`*[_type == "words"]{
            title,
            subTitle, 
            url,
            body,
            sequence
        }`)
        .then((data) => setWordsData(data.sort(compare)))
        .catch(console.error);
    }, [])

    if (!wordsData) return <Spinner />
    return (
        <main className="text-super-container">

               {/* Only show on small screens: */}
               <header className="small-conditional-header">
               <NavLink to="/nihility">
        <RiArrowGoBackLine id="" label="Back" 
                    style={{height: '3em', width: '3em', color: "white"}}/>
                    </NavLink>
        <img src={wordsImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

        <section className="text-layout-cols">


 {/* back button / spacer */}
 <div className="back-btn-container">
                <NavLink to="/nihility">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>

        <div className="text-display">
        <h1>Words</h1>
{wordsData && wordsData.map((words, index) => (
      <div key={index} className="words-item">
          <h3>{words.title}</h3>
          <h4>{words.subTitle}</h4>
          <BlockContent blocks={words.body} projectId="cm02c6gj" dataset="production"/>
      </div>  
    ))}


        </div>

        <div className="other-items">
         <NihilityBackNav />
         <MenuBackNav />
         </div>

        </section>

        </main>
    )
}




