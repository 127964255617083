import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom"
import Home from "./components/Home"
import Menu from "./components/Menu"
import Intro from "./components/Intro"
import Nihility from "./components/Nihility"
import Kunstkrud from "./components/Kunstkrud"
import Zounds from "./components/Zounds"
import Gigs from "./components/Gigs"
import Linx from "./components/Linx"
import AuralAbject from "./components/AuralAbject"
import Contact from "./components/Contact"
import Drawing from "./components/Drawing"
import Musick from "./components/Musick"
import News from "./components/News"
import Performance from "./components/Performance"
import Skulpchur from "./components/Skulpchur"
import SingleSkulpchur from './components/SingleSkulpchur';
import Words from "./components/Words"
import SingleDrawing from "./components/SingleDrawing"
import SinglePerformance from './components/SinglePerformance'
import Spinner from './components/Spinner'
import Arts from './components/Arts'
import ArtsSingle from './components/ArtsSingle'

function App() {

  return (
    <BrowserRouter>
    <Switch>
      <Route component={Home} path="/" exact />
      <Route component={Intro} path="/intro" />
      <Route component={Menu} path="/menu" />
      <Route component={Nihility} path="/nihility" />
      <Route component={Kunstkrud} path="/kunstkrud" />
      <Route component={Zounds} path="/zounds" />
      <Route component={Gigs} path="/gigs" />
      <Route component={News} path="/news" />
      <Route component={Words} path="/words" />
      <Route component={Musick} path="/musick" />
      <Route component={SinglePerformance} path="/performance/:slug" />
      <Route component={Performance} path="/performance" />
      <Route component={SingleSkulpchur} path="/skulpchur/:slug" />
      <Route component={Skulpchur} path="/skulpchur" />

      {/*  */}
      <Route component={ArtsSingle} path="/arts/:slug" />
      <Route component={Arts} path="/arts" />

      {/*  */}

      <Route component={Spinner} path="/spinner" />
     
      <Route component={Linx} path="/linx" />
      <Route component={Contact} path="/contact" />
      <Route component={SingleDrawing} path="/drawing/:slug" />
      <Route component={Drawing} path="/drawing" />
      <Route component={AuralAbject} path="/auralabject" />

    </Switch>
    </BrowserRouter>
  );
}

export default App;
