import React from 'react'
import { NavLink } from 'react-router-dom'
// import image from '../assets/w.turq.humansacrificegreen3.jpg'
import image from '../assets/replace_front_page.jpg'


export default function Home() {
    return (
        <NavLink to="/intro">
        <div className="home-container" style={{backgroundImage: `url(${image})`}}>
             {/* <NavLink to="/intro"> */}
            {/* <img src={image} alt="" className="landing-img" label="Human Sacrafice"/> */}
            
            
        </div>

        </NavLink>
    )
}
