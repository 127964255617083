import React from 'react'

const HoverImage = ({image1, image2}) => {
  return (
    <div id="nav-hover">
    <img src={image1} alt="nav" />
    <img src={image2} alt="nav" />
    </div>
  )
}

export default HoverImage