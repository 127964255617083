import React from 'react'
import '../styles/nihilitykunstkrudzounds.css'
import { NavLink } from 'react-router-dom'
import menuImage1a from '../assets/gigs.gif'
import menuImage1b from '../assets/gigs2.gif'
import menuImage2a from '../assets/news.gif'
import menuImage2b from '../assets/news2.gif'
import menuImage3a from '../assets/words.gif'
import menuImage3b from '../assets/words2.gif'
import menuImage4a from '../assets/linx.gif'
import menuImage4b from '../assets/linx.2.gif'
import menuImage5a from '../assets/contact.1.gif'
import menuImage5b from '../assets/contact.2.gif'
import MenuBackNav from './MenuBackNav'

import background from '../assets/replace_nihilty.jpg'

import HoverImage from './HoverImage'

const Nihility = () => {
  return (
    <main className="main-container" style={{backgroundImage: `url(${background})`}}>

        <section className="col-display">
            <div className="page-items">
                    <div className="page-item" id="nav-hover">
                    <NavLink to="/gigs">
                    <HoverImage image1={menuImage1a} image2={menuImage1b}/>
                    </NavLink>
                    </div>

                    <div className="page-item" id="nav-hover">
                    <NavLink to="/news">
                        <HoverImage image1={menuImage2a} image2={menuImage2b}/>
                    </NavLink>
                    </div>
                    
                    <div className="page-item" id="nav-hover">
                    <NavLink to="/words">
                    <HoverImage image1={menuImage3a} image2={menuImage3b}/>
                    </NavLink>
                    </div>
            </div>

            <div className="other-items-nihility-kunstkrud-zounds">
                    <div className="nav-pair">
                            <div className="linx" id="nav-hover">
                                <NavLink to="/linx">
                                <HoverImage image1={menuImage4a} image2={menuImage4b}/>
                                </NavLink>
                            </div>
                            
                            <div className="contact" id="nav-hover">
                                <NavLink to="/contact">
                                <HoverImage image1={menuImage5a} image2={menuImage5b}/>
                                </NavLink>
                            </div>
                    </div>
                    <div>
                    <MenuBackNav />
                    </div>
                    
            </div>
        </section>
    </main>
  )
}

export default Nihility