import React from 'react'
import BlockContent from "@sanity/block-content-to-react";
import AudioPlayer2 from './AudioPlayer2'

const serializers = {
    types: {
        inlineAudio: ({ node }) => {
            return <AudioPlayer2 {...node} />
        }
    }
}

const AudioBlockContent = (props) => {
    const {audio} = props;

    console.log(audio)
    return (
          <div className="audio-item">
              <div className="audio-item-text">
                    <h3>{audio.artist}</h3>
                    <h3>{audio.title}</h3>
                </div>
                <BlockContent blocks={audio.audio} serializers={serializers} />    
          </div>
    )
}

export default AudioBlockContent
