import React, {useState, useEffect} from 'react'
import '../styles/musickauralabject.css'

import { RiArrowGoBackLine } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'

import ZoundsBackNav from './ZoundsBackNav'
import MenuBackNav from './MenuBackNav'
import sanityClient from '../client.js'
import AudioBlockContent from './AudioBlockContent'
import Spinner from './Spinner'
import SmallMenuBackNav from './SmallMenuBackNav'
import musickImage from '../assets/musick.gif'

export default function Musick() {

    const [musickData, setMusickData] = useState(null);

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(() => {
        sanityClient.fetch(`*[_type == "musick"]{
            title,
            slug,
            sequence,
            "audioFiles":audioFiles[]->{
                title,
                slug,
                body,
                artist,
                audio
            }
        }`)
        .then((data) => setMusickData(data.sort(compare)))
        .catch(console.error);
    },[])

    console.log(musickData)
    if (!musickData) return <Spinner/>
    return (
        <main className="single-artwork-super-container">
        {/* Only show on small screens: */}
        <header className="small-conditional-header">
                <NavLink to="/zounds">
                <RiArrowGoBackLine id="" label="Back" 
                            style={{height: '3em', width: '3em', color: "white"}}/>
                </NavLink>
                <img src={musickImage} className="" alt="" />
                <SmallMenuBackNav />
                </header>

        <section className="single-artwork-cols">
      {/* back button / spacer */}
      <div className="back-btn-container">
                <NavLink to="/zounds">
                <RiArrowGoBackLine id="" label="Back" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>


        {/* Display */}
        <div className="artwork-display">
            <div className="musick-container">
            <div className="spacer">
                        <h1>Musick</h1>
                    </div>
        {musickData && musickData.map((musick, index)=> (
                    <div key={index}>
                        <h4>{musick.title}</h4>
                        <div className="audio-item-container">
                            {
                                musick.audioFiles && musick.audioFiles.map((item, index)=>(
                                    <AudioBlockContent audio={item} key={index} />
                                ))
                            }
                        </div>
 
                    </div>
                ))}

            </div>
        </div>

        <div className="other-items">
                 <ZoundsBackNav />
                <MenuBackNav />
        </div>



        </section>

            {/* <div className="musick-container">
                <div className="spacer">
                    <h1>Musick</h1>
                </div>
                {musickData && musickData.map((musick, index)=> (
                    <div key={index}>
                        <h4>{musick.title}</h4>
                        <div>
                            {
                                musick.audioFiles && musick.audioFiles.map((item, index)=>(
                                    <AudioBlockContent audio={item} key={index} />
                                ))
                            }
                        </div>
                    </div>
                ))}
            </div>

            <div className="side-nav-container" id="col-2">
                <ZoundsBackNav />
                <MenuBackNav />
            </div> */}
        </main>
    )
}
