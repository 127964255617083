import React from 'react'
import { NavLink } from 'react-router-dom'
import yellow_a from '../assets/yellow-beast-sm.png'
import yellow_b from '../assets/yellow-beast-sm-blur.png'
import '../styles/menubacknav.css'

export default function MenuBackNav() {
    return (
        <div className="yellow-back">
            <div id="nav-hover">
                <NavLink to="/menu">
                <img src={yellow_a} className="menu-back-image" alt="" />
                <img src={yellow_b} className="menu-back-image" id="img-hover" alt="" />
                </NavLink>
            </div>
        </div>
    )
}
