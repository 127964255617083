import React, {useState} from 'react'
import {FaPlay, FaPause} from 'react-icons/fa'

const AudioPlayer2 = (props) => {
    
    const [audio1, setAudio1] = useState()
    const [playing, setPlaying] = useState(false)
    
    const { _ref: ref } = props.asset
    const assetRefParts = ref.split('-')
    const id = assetRefParts[1]
    const format = assetRefParts[2]
    const assetUrl = `https://cdn.sanity.io/files/${process.env.REACT_APP_SANITY_PROJECT_ID}/${process.env.REACT_APP_SANITY_DATASET}/${id}.${format}`
    
    const playAudio = () => {
        try {
            if(!audio1){
                const audio = new Audio(assetUrl)
                setAudio1(audio)
                if(!playing){
                    audio.play()
                    setPlaying(true)
                }else{
                    audio.pause()
                    setPlaying(false)
                }

               
            }else {
                if(!playing){
                    audio1.play()
                    setPlaying(true)
                }else{
                    audio1.pause()
                    setPlaying(false)
                }
                
            }
        } catch (error) {
            console.log(error)
        }
    }

    


  return (
    <div>
        <button type="button" onClick={playAudio} aria-label="Play audio" id="btn-audio">
        {playing ? <FaPause label="pause" id="pause" /> : <FaPlay label="play" id="play" />}
        </button>
    </div>
  )
}

export default AudioPlayer2