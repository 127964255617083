import React, {useState, useEffect} from 'react'
import sanityClient from '../client.js'
// import NihilityBackNav from './NihilityBackNav.js';
import KunstKrudBackNav from './KunstKrudBackNav.js';
import MenuBackNav from './MenuBackNav.js';
import { NavLink } from 'react-router-dom'
import Spinner from './Spinner'

const Arts = () => {

    const [skulpchurs, setSkulpchurs] = useState(null)

    const [hover, setHover ] = useState(false)

    const [ hoveredImage, setHoveredImage] = useState(null)

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(() => {
        sanityClient.fetch(`*[_type == "skulpchur"]{
            title,
            slug,
            sequence,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`)
        .then((data) => setSkulpchurs(data.sort(compare)))
        .catch(console.error);
    }, [])

    const mouseOver = (e) => { 
        setHover(true)
        setHoveredImage({src: e.target.currentSrc, alt: e.target.alt})
    }

    const mouseOut = (e) => {
        setHover(false)
    }

    if (!skulpchurs) return <Spinner />
  return (
    <main className="arts">

        <section className="grid">
        {skulpchurs && skulpchurs.map((skulpchur, index) => (
                   
                   <NavLink to={"/skulpchur/"+skulpchur.slug.current} key={skulpchur.slug.current}>
                   {/* <div key={index} className="grid-item" > */}
                       <img src={skulpchur.mainImage.asset.url} alt={skulpchur.title} key={index} className="" onMouseOver={mouseOver} onMouseOut={mouseOut}/>
                   {/* </div> */}
                   </NavLink>
                  
               ))}
        </section>

        <section className="frame">
                    {hover ? 
                   <div className="">
                   <img src={hoveredImage.src} alt={hoveredImage.alt}/>
                   <h4>{hoveredImage.alt}</h4>
                   </div>
                   : <div></div>} 
        </section>

        <nav className="image-nav">
                <KunstKrudBackNav />
                <MenuBackNav />
        </nav>

    </main>
  )
}

export default Arts