import React from 'react'
import { NavLink } from 'react-router-dom'
import menuImage1 from '../assets/nihility_blank.gif'
import menuImage2 from '../assets/kunstkrud2_blank.gif'
import menuImage3 from '../assets/zounds_blank.gif'
import '../styles/menu.css'


export default function Menu() {
    return (
        <div className="menu-container">
            
            <div className="menu-nav-container">
            
                <div className="menu-nav-a" label="nihility">
                <NavLink to="/nihility">
                   
                <img src={menuImage1} className="nav-image" id="nav-image-1" alt="" />
                </NavLink>
                </div>
           
                <div className="menu-nav-b" label="kunstkrud">
                <NavLink to="/kunstkrud">
                <img src={menuImage2} className="nav-image" id="nav-image-2" alt="" />
                </NavLink>
                </div>    
            
                <div className="menu-nav-c" label="zounds">
                <NavLink to="/zounds">
                <img src={menuImage3} className="nav-image" id="nav-image-3" alt="" />
                </NavLink>
                </div>
           
            </div>
            
        </div>
    )
}
