import React from 'react'
import {NavLink} from 'react-router-dom'
import navImage1a from '../assets/back-kunstkrud.jpg'
import navImage1b from '../assets/back-kunstkrud.jpg'

const KunstKrudBackNav = () => {
    return (
        <div className=""  id="nav-hover"aria-label='Back'>
        <NavLink to="/kunstkrud">
        <img src={navImage1a} className="side-nav-image" alt="" />
        <img src={navImage1b} className="side-nav-image" alt="" />
        </NavLink>
    </div>
    )
}

export default KunstKrudBackNav