import React, {useState, useEffect} from 'react'
import '../styles/drawingskulpchurperformance.css'

import sanityClient from '../client.js'
import KunstKrudBackNav from './KunstKrudBackNav.js';
import MenuBackNav from './MenuBackNav.js';
import { NavLink } from 'react-router-dom'
import { RiArrowGoBackLine } from 'react-icons/ri'

import Spinner from './Spinner'
import drawing from '../assets/drawing.gif'

import SmallMenuBackNav from './SmallMenuBackNav'

export default function Drawing() {

    const [drawings, setDrawings] = useState(null)
    const [hover, setHover] = useState(false)
    const [ hoveredImage, setHoveredImage] = useState(null)

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }
    
    useEffect(() => {
        sanityClient.fetch(`*[_type == "drawing"]{
            title, 
            slug,
            sequence,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            dateInfo,
            projectInfo,
   
        }`)
        .then((data) => setDrawings(data.sort(compare)))
        .catch(console.error)
    }, [])

    const mouseOver = (e) => {
        console.log(e.target)
        setHover(true)
        setHoveredImage({src: e.target.currentSrc, alt: e.target.alt, title: e.target.alt})
    }

    const mouseOut = (e) => {
        setHover(false)
    }
    // 
    if (!drawings) return <Spinner/>
    return (
        <main className="super-container" >

               {/* Only show on small screens: */}
        <header className="small-conditional-header">
        <NavLink to="/kunstkrud">
                <RiArrowGoBackLine id="" label="Back" 
                            style={{height: '3em', width: '3em', color: "white"}}/>
                </NavLink>
        <img src={drawing} className="" alt="" />
        <SmallMenuBackNav />
        </header>


        <section className="col-display" >

            <div className="grid" >
                {drawings && drawings.map((drawing, index) => (
                    <div key={index}>
                    {
                    drawing.slug?
                    <NavLink to={"/drawing/"+drawing.slug.current} key={index}>
                        <div key={index} className="grid-item">
                            <img src={drawing.mainImage.asset.url} alt={drawing.title} className="grid-image" 
                            
                            onMouseOver={mouseOver}
                            onMouseOut={mouseOut}
                            />
                        </div>
                    </NavLink>
                    :
                    null
                    }
                    </div>
                ))}
            </div>

            <div className="display-frame">
                   {hover ? 
                   <div className="display-frame-image">
                    
                   <img src={hoveredImage.src} alt={hoveredImage.alt}/>
                   <h4>{hoveredImage.title}</h4>
                   {/* <p>{hoveredImage.date ? hoveredImage.date : ""}</p>
                   <p>{hoveredImage.project ? hoveredImage.project : ""}</p> */}
                   </div>
                   : null} 
            </div>

            <div className="other-items">
                <KunstKrudBackNav />
                <MenuBackNav />
            </div>

            </section>
        </main>
    )
}
