import React, { useState, useEffect } from 'react'
import KunstKrudBackNav from './KunstKrudBackNav.js';
import AudioBlockContent from './AudioBlockContent'
import MenuBackNav from './MenuBackNav.js';
import sanityClient from '../client.js'
import Spinner from './Spinner'
import { NavLink } from 'react-router-dom'
import { RiArrowGoBackLine } from 'react-icons/ri'

import SmallMenuBackNav from './SmallMenuBackNav'
import auralImage from '../assets/auralabject.gif'


export default function AuralAbject() {

    const [musickData, setMusickData] = useState(null);

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(() => {
        sanityClient.fetch(`*[_type == "auralAbject"]{
            title,
            slug,
            sequence,
            "audioFiles":audioFiles[]->{
                title,
                slug,
                body,
                artist,
                audio
            }
        }`)
        .then((data) => setMusickData(data.sort(compare)))
        .catch(console.error);
    },[])

    console.log(musickData)

    if (!musickData) return <Spinner/>
    return (
        <main className="single-artwork-super-container">

              {/* Only show on small screens: */}
              <header className="small-conditional-header">
              <NavLink to="/kunstkrud">
                <RiArrowGoBackLine id="" label="Back" 
                            style={{height: '3em', width: '3em', color: "white"}}/>
                </NavLink>
        <img src={auralImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

        <section className="single-artwork-cols">


             {/* back button / spacer */}
        <div className="back-btn-container">
                <NavLink to="/skulpchur">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>

        <div className="artwork-display">
                <div className="musick-container">
                    <div className="spacer">
                        <h1>Aural Abject</h1>
                    </div>
                    {musickData && musickData.map((item, index)=>(
                        <div key={index}>
                            <h4>{item.title}</h4>
                            {console.log(item.audioFiles)}
                            <div>
                                {
                                item.audioFiles && item.audioFiles.map((audio, index)=>(
                                    <AudioBlockContent audio={audio} key={index} />

                                )) 
                                }
                            </div>
                       
                        </div>
                    ))}
                </div>
            </div>

            <div className="other-items">
                <KunstKrudBackNav />
                <MenuBackNav />
            </div>
            </section>
        </main>
    )
}
