import React from 'react'
import { NavLink } from 'react-router-dom'
import { RiArrowGoBackLine } from 'react-icons/ri'
import contactImage from '../assets/contact.2.gif'
import SmallMenuBackNav from './SmallMenuBackNav'
import NihilityBackNav from './NihilityBackNav.js'
import MenuBackNav from './MenuBackNav.js'
import '../styles/gigsnewswords.css'

export default function Contact() {
    return (

        <main className="text-super-container">

                 {/* Only show on small screens: */}
                 <header className="small-conditional-header">
               <NavLink to="/nihility">
        <RiArrowGoBackLine id="" label="Back" 
                    style={{height: '3em', width: '3em', color: "white"}}/>
                    </NavLink>
        <img src={contactImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

        <section className="text-layout-cols">

             {/* back button / spacer */}
            <div className="back-btn-container">
                <NavLink to="/nihility">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
            </div>

            <div className="text-display">
               
                <h1><a class="contact" href="mailto:fungalhex@hotmail.com">fungalhex@hotmail.com</a></h1>
              
            </div>

            <div className="other-items">
            <NihilityBackNav />
            <MenuBackNav />
            </div>

            </section>

        </main>
        
      


    )
}
