import React, {useEffect, useState} from 'react'
import sanityClient from "../client.js"
import BlockContent from "@sanity/block-content-to-react";
import Spinner from './Spinner'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'

import newsImage from '../assets/news.gif'

import SmallMenuBackNav from './SmallMenuBackNav'
import NihilityBackNav from './NihilityBackNav.js'
import MenuBackNav from './MenuBackNav.js'
import '../styles/gigsnewswords.css'

export default function News() {

    const [newsData, setNewsData] = useState(null);

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(() => {
        sanityClient.fetch(`*[_type == "news"]{
            title,
            dateInfo,
            body
        }`)
        .then((data) => setNewsData(data.sort(compare)))
        .catch(console.error);
    }, []);

    if (!newsData) return <Spinner/>
    return (
        <main className="text-super-container">

               {/* Only show on small screens: */}
               <header className="small-conditional-header">
               <NavLink to="/nihility">
        <RiArrowGoBackLine id="" label="Back" 
        
                    style={{height: '3em', width: '3em', color: "white"}}/>
                </NavLink>
        <img src={newsImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

        <section className="text-layout-cols">


 {/* back button / spacer */}
 <div className="back-btn-container">
                <NavLink to="/nihility">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>

        <div className="text-display">
        <h1>News</h1>
                {newsData && newsData.map((news, index) => (
                    <div key={index} className="news-item">
                    <h3>{news.title}</h3>
                    <BlockContent blocks={news.body} projectId="cm02c6gj" dataset="production" />
                    </div>
                ))}


        </div>

        <div className="other-items">
         <NihilityBackNav />
         <MenuBackNav />
         </div>

        </section>

        </main>
    )
}




