import React, {useState, useEffect} from 'react'
import '../styles/drawingskulpchurperformance.css'

import sanityClient from '../client.js'

import KunstKrudBackNav from './KunstKrudBackNav.js';
import MenuBackNav from './MenuBackNav.js';
import { NavLink } from 'react-router-dom'
import Spinner from './Spinner'
import { RiArrowGoBackLine } from 'react-icons/ri'
import skulpchur from '../assets/skulpchur.gif'
import SmallMenuBackNav from './SmallMenuBackNav'

const Skulpchur = () => {

    const [skulpchurs, setSkulpchurs] = useState(null)

    const [hover, setHover ] = useState(false)

    const [ hoveredImage, setHoveredImage] = useState(null)

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(() => {
        sanityClient.fetch(`*[_type == "skulpchur"]{
            title,
            slug,
            sequence,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            dateInfo,
            projectInfo
        }`)
        .then((data) => setSkulpchurs(data.sort(compare)))
        .catch(console.error);
    }, [])

    const mouseOver = (e) => { 
        setHover(true)
        setHoveredImage({src: e.target.currentSrc, alt: e.target.alt, title: e.target.alt})
    }

    const mouseOut = (e) => {
        setHover(false)
    }

    if (!skulpchurs) return <Spinner />
  return (
    <main className="super-container">

        {/* Only show on small screens: */}
        <header className="small-conditional-header">
        <NavLink to="/kunstkrud">
                <RiArrowGoBackLine id="" label="Back" 
                            style={{height: '3em', width: '3em', color: "white"}}/>
                </NavLink>
        <img src={skulpchur} className="" alt="" />
        <SmallMenuBackNav />
        </header>

        <section className="col-display">

                {/* Grid */}
            <div className="grid">
            {skulpchurs && skulpchurs.map((skulpchur, index) => (
                   <div key={index}>
                   {
                    skulpchur.slug ? 
                   <NavLink to={"/skulpchur/"+skulpchur.slug.current} key={skulpchur.slug.current}>
                   <div className="grid-item" >
                       <img src={skulpchur.mainImage.asset.url} alt={skulpchur.title} className="grid-image" onMouseOver={mouseOver} onMouseOut={mouseOut}/>
                   </div>
                   </NavLink>
                    :
                    null
                        }
                   </div>
               ))}
            </div>

        {/* Image display */}
            <div className="display-frame">
            {hover ? 
                   <div className="display-frame-image">
                   <img src={hoveredImage.src} alt={hoveredImage.alt}/>
                   <h4>{hoveredImage.title}</h4>
                   {/* <p>{hoveredImage.date ? hoveredImage.date : ""}</p>
                   <p>{hoveredImage.project ? hoveredImage.project : ""}</p> */}
                   </div>
                   : null} 
            </div>

                {/* Nav display */}
            <div className="other-items">
                <KunstKrudBackNav />
                <MenuBackNav />
            </div>

        </section>

    </main>
  )
}

export default Skulpchur