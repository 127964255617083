import React, {useState, useEffect } from 'react'
import sanityClient from "../client.js"
import Spinner from './Spinner'

import { NavLink } from 'react-router-dom'
import { RiArrowGoBackLine } from 'react-icons/ri'

import linxImage from '../assets/linx.2.gif'
import SmallMenuBackNav from './SmallMenuBackNav'
import NihilityBackNav from './NihilityBackNav.js'
import MenuBackNav from './MenuBackNav.js'
import '../styles/gigsnewswords.css'

export default function Linx() {

    const [linx, setLinx] = useState(null)

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(()=> {
        sanityClient.fetch(`*[_type == "linx"]{
            title, 
            url,
            sequence
        }`)
        .then((data)=> setLinx(data.sort(compare)))
        .catch(console.error)
    },[])

    if(!linx) return <Spinner />
    return (
        <main className="text-super-container">

                   {/* Only show on small screens: */}
                   <header className="small-conditional-header">
               <NavLink to="/nihility">
        <RiArrowGoBackLine id="" label="Back" 
                    style={{height: '3em', width: '3em', color: "white"}}/>
                    </NavLink>
        <img src={linxImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

        <section className="text-layout-cols">

             {/* back button / spacer */}
        <div className="back-btn-container">
                <NavLink to="/nihility">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>
           
        <div className="text-display linx-container">
        {/* <h1>Linx</h1> */}

        {linx && linx.map((link, index) => (
            <p key={index} className="letterspaced"><a href={link.url}>{link.title}</a></p>
        ))}
            
        </div>

        <div className="other-items">
         <NihilityBackNav />
         <MenuBackNav />
         </div>

      
            </section>
        </main>
    )
}
