import React from 'react'
import { NavLink } from 'react-router-dom'
import backZounds_a from '../assets/back-zounds.jpg'
import backZounds_b from '../assets/back-zounds.jpg'

export default function ZoundsBackNav() {
    return (
        <div className="">
            <div id="nav-hover" >
                <NavLink to="/zounds">
                <img src={backZounds_a} className="side-nav-image" alt="" />
                <img src={backZounds_b} className="side-nav-image" alt="" />
                </NavLink>
            </div>
        </div>
    )
}
