import React, { useEffect, useState } from 'react'
import '../styles/singleartworkitem.css'

import sanityClient from '../client.js'
import { useParams } from 'react-router-dom'
import {IoIosArrowBack} from 'react-icons/io'
import {IoIosArrowForward} from 'react-icons/io'
import { RiArrowGoBackLine } from 'react-icons/ri'
import KunstKrudBackNav from './KunstKrudBackNav.js'
import MenuBackNav from './MenuBackNav.js'
import { NavLink } from 'react-router-dom'
import Spinner from './Spinner'
import SmallMenuBackNav from './SmallMenuBackNav'
import drawingImage from '../assets/drawing.gif'

import ArtworkModal from './ArtworkModal'
import { RiZoomInLine } from "react-icons/ri";




const SingleDrawing = () => {

    const [drawing, setDrawing] = useState(null)
    const { slug } = useParams();
    const [counter, setCounter] = useState(0)
    const [artworksLength, setArtworksLength] = useState(0)
    
    const increment = () => {
        const plusValue = counter + 1
        if(plusValue < artworksLength){
            setCounter(plusValue)   
        }else{
            setCounter(0)
        }   
    }

    const decrement = () => {
        const minusValue = counter -1
        if(minusValue > 0){
        setCounter(minusValue)
        }else{
        setCounter(artworksLength -1)
        }
    }
    
    useEffect(() => {
        sanityClient.fetch(`*[_type == "drawing" && slug.current == "${slug}"]{
            title,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                },
            },
            "artworks": artworks[]->{
                title,
                mainImage{
                    asset->{
                        url,
                        _id
                    },
                },
                imageAlt,
                description
            },
            dateInfo,
            projectInfo
        }`)
        .then((data) => setDrawing(data[0]))
        .catch(console.error);
    }, [slug]);

    useEffect(()=> {
        if(!drawing){
            setArtworksLength(0)
        }else{
            setArtworksLength(drawing.artworks ? drawing.artworks.length: 0)
        }
    }, [drawing])

    const dimensions = (string) => {
        const twoParts = string.split("-")
        const dimensions = twoParts[1].split("x")
        const one = parseFloat(dimensions[0])
        const two = parseFloat(dimensions[1].slice(0,-4))
        console.log(one, two)
        if(one > two){
            return true
        }else{
            return false
        }
    }

    const artworkRender = () => {
        if(artworksLength === 0){
            return <div><h1>No images for this drawing</h1></div>
        }else{
            const imageRatio = dimensions(drawing.artworks[counter].mainImage.asset.url)
            return <img src={drawing.artworks[counter].mainImage.asset.url} alt={drawing.title} style={{width: `${imageRatio ? "": "auto"}`, height: `${imageRatio ? "auto" : ""}`, maxWidth: `${imageRatio ? "100%" : ""}`, maxHeight: `${imageRatio ? "" : "100%"}`   }}/>
        }
    }

    const [modal, setModal] = useState(false)

    const handleModal = () => {
        setModal(!modal)
    }

    console.log(drawing)

 
    if (!drawing) return <Spinner/>
    return (
        <main className="single-artwork-super-container">
            {modal && <ArtworkModal handleModal={handleModal} setModal={setModal} increment={increment} decrement={decrement} artworkRender={artworkRender}/>}
            {/* Only show on small screens: */}
            <header className="small-conditional-header">
        <RiArrowGoBackLine id="" label="Back" 
                    style={{height: '3em', width: '3em', color: "white"}}/>
        <img src={drawingImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

            <section className="single-artwork-cols">
        {/* back button / spacer */}
        <div className="back-btn-container">
                <NavLink to="/drawing">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>

            
            <div className="artwork-display">
                <div className="display">
                {/* {artworkRender()} */}
                <img src={drawing.artworks[counter].mainImage.asset.url} alt={drawing.artworks[counter].mainImage.alt} />
                <h4 >{drawing.artworks[counter].description ? drawing.artworks[counter].description : ""}</h4>
                <button aria-label="open image modal" className="zoom-btn" onClick={handleModal}><RiZoomInLine style={{height: '2em', width: '2em', color: "white"}}/></button>
                </div>

                <div className="btn-container">
                <IoIosArrowBack id="" label="Back" 
                        style={{height: '4em', width: '4em', color: "white"}}
                        onClick={decrement} className="arrow" aria-label="Previous image"/>
                        
                <IoIosArrowForward id="" label="Forward" 
                        style={{height: '4em', width: '4em', color: "white"}}
                        onClick={increment} className="arrow" aria-label="Next image"/>
                </div>
       
            </div>

        
        

            <div className="other-items">
            <KunstKrudBackNav />
                <MenuBackNav />
            </div>

            </section>
        </main>
    )
}

export default SingleDrawing
