import React, {useState, useEffect} from 'react'
// import SideNav from './SideNav'
import sanityClient from "../client.js"
import BlockContent from "@sanity/block-content-to-react";
import Spinner from './Spinner'
import { NavLink } from 'react-router-dom'

import { RiArrowGoBackLine } from 'react-icons/ri'
import gigsImage from '../assets/gigs.gif'
import SmallMenuBackNav from './SmallMenuBackNav'
import NihilityBackNav from './NihilityBackNav.js'
import MenuBackNav from './MenuBackNav.js'
import '../styles/gigsnewswords.css'

export default function Gigs() {

    const [gigData, setGigData] = useState(null)

    const compare = (a, b) => {
        if( a.sequence < b.sequence){
            return 1;
        }
        if( a.sequence > b.sequence){
            return -1
        }
        return 0;
    }

    useEffect(() => {
        sanityClient.fetch(`*[_type == "gig"]{
            title,
            dateInfo,
            body,
            sequence
        }`)
        .then((data) => setGigData(data.sort(compare)))
        .catch(console.error)
    }, [])

    if(!gigData) return <Spinner/>
    return (
        <main className="text-super-container">

               {/* Only show on small screens: */}
               <header className="small-conditional-header">
               <NavLink to="/nihility">
        <RiArrowGoBackLine id="" label="Back" 
                    style={{height: '3em', width: '3em', color: "white"}}/>
                    </NavLink>
        <img src={gigsImage} className="" alt="" />
        <SmallMenuBackNav />
        </header>

        <section className="text-layout-cols">


 {/* back button / spacer */}
 <div className="back-btn-container">
                <NavLink to="/nihility">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '4em', width: '4em', color: "white"}}/>
                 </NavLink>
        </div>

        <div className="text-display">
        <h1>Gigs</h1>
                {gigData && gigData.map((gig, index) => (
                    <div key={index}        className="gigs-item">
                        <h3>{gig.title}</h3>
                        <BlockContent blocks={gig.dateInfo} projectId="cm02c6gj" dataset="production"/>
                        <BlockContent blocks={gig.body} projectId="cm02c6gj" dataset="production"/>
                        <hr></hr>
                    </div>
                ))}


        </div>

        <div className="other-items">
         <NihilityBackNav />
         <MenuBackNav />
         </div>

        </section>

        </main>
    )
}
