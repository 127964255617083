import React, { useEffect, useState } from 'react'
import sanityClient from '../client.js'
import { useParams } from 'react-router-dom'
import {IoIosArrowBack} from 'react-icons/io'
import {IoIosArrowForward} from 'react-icons/io'
import { RiArrowGoBackLine } from 'react-icons/ri'
import NihilityBackNav from './NihilityBackNav.js';
import MenuBackNav from './MenuBackNav.js';
import { NavLink } from 'react-router-dom'
import Spinner from './Spinner'


const ArtsSingle = () => {

    const [skulpchur, setSkulpchur] = useState(null);
    const { slug } = useParams();
    const [counter, setCounter] = useState(0)
    const [artworksLength, setArtworksLength] = useState(0)

    const increment = () => {
        const plusValue = counter + 1
        if(plusValue < artworksLength){
            setCounter(plusValue)   
        }else{
            setCounter(0)
        }   
    }

    const decrement = () => {
        const minusValue = counter -1
        if(minusValue > 0){
        setCounter(minusValue)
        }else{
            setCounter(artworksLength -1)
        }
    }

    const artworkRender = () => {
        if(artworksLength === 0){
            return <div><h1>No images for this skulpchur</h1></div>
        }else{
            return <img src={skulpchur.artworks[counter].mainImage.asset.url} alt={skulpchur.title} className="artwork-image"/>

        }
    }


    useEffect(() => {
        sanityClient.fetch(`*[slug.current == "${slug}" && _type == "skulpchur"]{
            title,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            "artworks": artworks[]->{
                mainImage{
                    asset->{
     
                        url
                    }
                }
            },
        }`)
        .then((data) => setSkulpchur(data[0]))
        .catch(console.error);
    }, [slug]);

    useEffect(()=> {
        if(!skulpchur) {
            setArtworksLength(0)
        }else{
            setArtworksLength(skulpchur.artworks ? skulpchur.artworks.length : 0)
        }
    }, [skulpchur])

    if (!skulpchur) return <Spinner/>


  return (
    <main className="arts-container">

        <section className="display-container">

            <nav className="gen-nav">
                <NavLink to="/skulpchur">
                <RiArrowGoBackLine id="" label="Forward" 
                    style={{height: '2em', width: '2em', color: "white"}}/>
                 </NavLink>

                 <div className="mobile-nav-art-single">
                 <NavLink to="/kunstkrud">
                    Kunstkrud
                 </NavLink>
                 <NavLink to="/menu">
                    Menu
                 </NavLink>
                 </div>
            </nav>

            <div>
                {artworkRender()}
                <h4>{skulpchur.title}</h4>
            </div>

            <div className="arrow-container">
                <IoIosArrowBack id="" label="Back" 
                        style={{height: '2em', width: '2em', color: "white"}}
                        onClick={decrement} className="arrow"/>
                        
                <IoIosArrowForward id="" label="Forward" 
                        style={{height: '2em', width: '2em', color: "white"}}
                        onClick={increment} className="arrow"/>
            </div>

        </section>

       
        <nav className="image-nav">
            {/* image nav for large screens */}
            <NihilityBackNav />
            <MenuBackNav />
        </nav>

    </main>
  )
}

export default ArtsSingle